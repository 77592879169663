export enum VariantType {
  GradePreK = 'GradePreK',
  GradeK = 'GradeK',
  Grade1 = 'Grade1',
  Grade2 = 'Grade2',
  Grade2C = 'Grade2C',
  Grade2M = 'Grade2M',
  Grade3 = 'Grade3',
  Grade4 = 'Grade4',
  Grade5 = 'Grade5',
  Grade6 = 'Grade6',
  Grade7 = 'Grade7',
  Grade8 = 'Grade8',
  Grade9 = 'Grade9',
  Grade10 = 'Grade10',
  Grade11 = 'Grade11',
  Grade12 = 'Grade12',
  Apprentice = 'Apprentice',
  ENAC = 'ENAC',
  ENACSpanish = 'ENACSpanish',
  GradeKto2 = 'GradeKto2',
  GradeKto2Spanish = 'GradeKto2Spanish',
  Grade3to5 = 'Grade3to5',
  Grade3to5Spanish = 'Grade3to5Spanish',
  OnlineTools = 'OnlineTools',
  Specialist = 'Specialist',
  StagesABC = 'StagesABC',
  StagesABCSpanish = 'StagesABCSpanish',
  Recruit = 'Recruit',
  SimplifiedCoreResources = 'SimplifiedCoreResources',
  None = '',
}
