import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ZbpIconComponent } from '@shared/zbp-icon/zbp-icon';

@Component({
  standalone: true,
  selector: 'zbp-button-component',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [CommonModule, ZbpIconComponent, NgbTooltip]
})
export class ButtonComponent {
  /** Text label for the button */
  @Input() label: string = '';
  /** Name of icon from zbpIconNames if button needs icon  */
  @Input() iconName: string = null;
  /** CSS color if button icon needs different fill color  */
  @Input() iconFillColor: string = 'none';
  /** Adjusts the size of the icon rendered.
   * <ul>
   * <li><strong>default</strong>: lets the icon do whatever it wants.</li>
   * <li><strong>font-size</strong>: forces icon to match font size.</li>
   * </ul>
   */
  @Input() iconSize: 'font-size' | 'default' = 'default';
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() tooltip: string | undefined;
  @Input() tooltipClass: string | undefined;
  /** Aria label to be used when extra accessibility is needed */
  @Input() ariaLabel: string = null;
  /** Theme of the button which can be 'primary', 'ghost', or 'greyGhost' */
  @Input() theme: 'primary' | 'secondary' | 'ghost' | 'greyGhost' | 'greySolid'
    | 'text' | 'primaryText' | 'blackText' | 'primaryTextLink' | 'icon' | 'icon-alt'
    | 'icon-white' | 'breadcrumbText' = 'primary';
  /** Disabled state of the button */
  @Input() disabled: boolean = false;
  @Input() useMinWidth: boolean = false;
  /**
   * Choose unit to use for button font size.
   * <ul>
   * <li><strong>em</strong>: Useful for allowing iconSize='font-size' to respect the button's parent's font size.</li>
   * <li><strong>rem</strong>: Is the default, allows buttons to have a standard font size across the app.</li>
   * </ul>
   **/
  @Input() fontSizeUnit: 'em' | 'rem' = 'rem';
  /** Unique identifier of the button */
  @Input() internalId: string;
  /** Type of the button which can be 'submit', 'button', or 'reset' */
  @Input() type: 'submit' | 'button' | 'reset' = 'button';
  /** Event emitter for click event */
  @Output() clickHandler: EventEmitter<Event> = new EventEmitter();

  /**
   * Handles the click event and emits the event if the button is not disabled
   * @param {Event} event - The DOM event triggered on button click
   */
  handleClick(event: Event): void {
    if (!this.disabled && !!this.clickHandler) {
      this.clickHandler.emit(event);
    }
  }

  constructor() { }
}
