export const licenseFieldNames = {
  isAssigned: 'isAssigned',
  licensesTotal: 'licensesTotal',
  licensesUsed: 'licensesUsed',
  skuId: 'skuId',
  description: 'description',
};

export const newLicensesFormGroupName = 'newLicensesGroup';

export const newLicensesFormArrayName = 'newLicenses';
