import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, GuardResult, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

import { AuthenticationService } from '../authentication.service';

/**
 * RedirectIfActive forwards users with active sessions away from the respective login to the root of the application
 */
// eslint-disable-next-line max-len
export function redirectIfActiveGuard(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GuardResult> {
  const onLoginPage = state.url.includes('/login');
  const router = inject(Router);
  return inject(AuthenticationService).authStatus.pipe(
    skipWhile(status => status === null),
    map((status) => {
      if (status && onLoginPage) {
        // we are already logged in, but trying to access the login page, redirect
        return router.parseUrl('');
      }
      // passes guard, allow through
      return true;
    }),
  );
}
