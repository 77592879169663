import { Injectable } from '@angular/core';
import { School } from '@models/school';
import { AccessDeniedReason } from '@shared/enums/access-denied-reason';
/**
 * Handles logic for allowing or blocking access to places throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class AccessControlService {
  /**
   * Checks if a school has valid licenses.
   * Order of checks is important, will short-circuit if certain requirements are met
   * @param {School} school The school whose licenses is being checked.
   * @returns {AccessDeniedReason | null} The reason for access denial, or null if access is granted.
   */
  schoolLicenseAccessDeniedCheck(school: School): AccessDeniedReason {
    // Scenario 1
    if (this.noLicensesForSchool(school)) {
      return AccessDeniedReason.NoLicenses;
    }

    // Scenario 2
    if (this.onlyFutureLicenses(school)) {
      return AccessDeniedReason.OnlyFutureLicenses;
    }

    // Scenario 3
    if (this.noSchoolIdNorActiveNorDistributedLicenses(school)) {
      // Standalone schools (non-District) are in charge of setting their school IDs, they may proceed.
      if (!school.districtId) {
        return null;
      }
      return AccessDeniedReason.NoSchoolIdNorActiveLicenses;
    }

    // Scenario 4
    if (this.noActiveNorDistributedLicensesHasSchoolId(school)) {
      return AccessDeniedReason.NoActiveNorDistributedLicensesHasSchoolId;
    }

    //Scenario 5
    if (this.noDistributedLicensesHasSchoolIdAndActiveLicense(school)) {
      return AccessDeniedReason.NoDistributedLicensesHasSchoolIdAndSActiveLicense;
    }

    return null;
  }

  // No licenses exist
  private noLicensesForSchool(school: School) {
    return school && !school.hasAvailableLicenses && !school.hasFutureActivation;
  }

  // Licenses exist, but set with a future date
  private onlyFutureLicenses(school: School) {
    return school && !school.hasAvailableLicenses && school?.hasFutureActivation;
  }

  // Licenses exist, but:
  // a) School ID isn't set, b) Licenses haven't been activated, c) Licenses need to be distributed.
  private noSchoolIdNorActiveNorDistributedLicenses(school: School) {
    return school
      && school.hasAvailableLicenses
      && !school.abbreviatedSchoolId
      && !school.hasLicensesAssigned
      && !school.hasLicensesDesignated;
  }

  // Licenses and School ID are set, but a) licenses haven't been activated or distributed
  private noActiveNorDistributedLicensesHasSchoolId(school: School) {
    return school
      && school.hasAvailableLicenses
      && school.abbreviatedSchoolId
      && !school.hasLicensesAssigned
      && !school.hasLicensesDesignated;
  }

  // Licenses exist but a) School ID is set, b) Licenses have been activated but need to be distributed
  private noDistributedLicensesHasSchoolIdAndActiveLicense(school: School) {
    return school
      && school.hasAvailableLicenses
      && school.abbreviatedSchoolId
      && school.hasLicensesAssigned
      && !school.hasLicensesDesignated;
  }

  isUnauthorizedRoute(url: string, additionalRoutes: string[] = []): boolean {
    const unAuthorizedRoutes: string[] = [
      '/logout',
      '/login',
      '/login/user',
      '/login/teacher',
      '/login/student',
      '/login/skof',
      '/login/forgot-password',
      '/invalid-token',
      '/set-password',
      '/account-setup',
      '/clever',
      '/lti-token-exchange',
    ].concat(additionalRoutes);
    return unAuthorizedRoutes.includes(url.replace(/\?.*$/, ''));
  }
}
