import { ProductType } from '@shared/enums/product-type';
import { RoleType } from '@shared/enums/role-type';
import { VariantType } from '@shared/enums/variant-type';
import { ClassCast } from '@shared/zb-object-helper/class-cast';

import { IUserSettings } from './user-settings';

export class FilterOptionSettings {
  productType: ProductType;
  variantType: VariantType;
  filterOptions: string[];
}

export class IUserProfileDetail extends IUserSettings {
  profileType: string = undefined;
  @ClassCast(FilterOptionSettings)
    currentFilterOptionSettings: FilterOptionSettings[] = [];
}

export enum ZbpPermission {
  CAN_CHANGE_PASSWORD = 'canChangePassword',
  CAN_CHANGE_STUDENT_PASSWORD = 'canChangeStudentPassword',
  CAN_MANAGE_CLASSROOM = 'canManageClassroom',
  CAN_MANAGE_LICENSES = 'canManageLicenses',
  CAN_MANAGE_SELF = 'canManageSelf',
  CAN_MANAGE_STUDENT = 'canManageStudent',
  CAN_MANAGE_PARENT = 'canManageParent',
}

/**
 * Requires implementing types to account for all permissions from enum.
 */
export type IPermissionsMap = Record<ZbpPermission, boolean>;

export abstract class ProfileDetailBase implements IUserProfileDetail, IPermissionsMap {
  profileType: string = null;
  canChangePassword = false;
  canChangeStudentPassword = false;
  canManageClassroom = false;
  canManageLicenses = false;
  canManageSelf = false;
  canManageStudent = false;
  canManageParent = false;
  suppressEmails = false;
  enableStudentAudioPrompts = false;
  currentFilterOptionSettings: FilterOptionSettings[] = null;
  viewingAsRole: RoleType | null = null;
  recentlyCompletedQuestIds: string[] = [];

  // constructor(values: any) {
  //   this.profileType = values.profileType;

  //   _.forEach(values, (value, key) => {
  //     if (Object.prototype.hasOwnProperty.call(this, key)) {
  //       this[key] = value;
  //     }
  //   });
  // }
}

export class AdministratorProfileDetail extends ProfileDetailBase { }
export class CustomerServiceAdministratorProfileDetail extends ProfileDetailBase { }
export class DistrictAdministratorProfileDetail extends ProfileDetailBase { }
export class SchoolAdministratorProfileDetail extends ProfileDetailBase { }
export class ParentProfileDetail extends ProfileDetailBase { }
export class TeacherProfileDetail extends ProfileDetailBase { }
export class StudentProfileDetail extends ProfileDetailBase {}

export const profileDetailTypes = {
  AdministratorProfileDetail,
  CustomerServiceAdministratorProfileDetail,
  DistrictAdministratorProfileDetail,
  ParentProfileDetail,
  SchoolAdministratorProfileDetail,
  StudentProfileDetail,
  TeacherProfileDetail,
};

export function createProfileDetail(values: any): IUserProfileDetail {
  const type = values.profileType ? values.profileType : 'Unknown';

  if (profileDetailTypes[type]) {
    return new profileDetailTypes[type](values);
  }

  throw new Error(`ProfileDetailType: ${type} does not exist`);
}
