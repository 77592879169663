import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigService } from '../appconfig.service';
import { AuthenticationService } from '../authentication.service';
import { UserService } from '../user.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private appConfig: AppConfigService,
    private authService: AuthenticationService,
    private userService: UserService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        tap(() => {
          // Nothing.
        }),
        catchError((err: HttpErrorResponse | Error) => {
          if ((err instanceof HttpErrorResponse && err.status === 401 && err.url.indexOf('user/login') === -1)
            || (!this.authService.apiToken && this.routeRequiresAuthorization(request.url))) {
            // Clear the user state and go to logout when user is logged out, hits an Api error, and is not on a
            // page that has its own error handling.
            this.userService.clearUserState();
            this.router.navigateByUrl('/logout');
          }

          if (err instanceof HttpErrorResponse && (this.isSiteDown(err) || this.isTimeout(err))) {
            this.appConfig?.setSiteStatus(false);
            //TODO: Do we need a Highlights site error page
            this.router.navigateByUrl('/site-error', {});
          }
          return throwError(err);
        })
      );
  }

  private isSiteDown(response: HttpErrorResponse): boolean {
    if (!response.url.includes(this.appConfig.apiUrl) && !response.url.includes('localhost:4200')) {
      // Response from other APIs should not send the site down.
      return false;
    }

    if (response.status === 503 && response.error instanceof Array) {
      const [error] = response.error;
      if (this.appConfig) {
        this.appConfig.siteStatusMessage = error;
      }
      return true;
    // eslint-disable-next-line
    } else if (response.status === 503) {
      return true;
    } else if (!response.status) {
      // Web browsers will not give access to the request (for good security reasons) when an OPTIONS request fails,
      // and this is the default message.
      //TODO: Do we need a custom Highlights message for site down?
      if (this.appConfig) {
        this.appConfig.siteStatusMessage = `<b>MyZBPortal</b> is currently unavailable due to unscheduled maintenance. We apologize for the inconvenience.`;
      }
      return true;
    }

    return false;
  }

  private isTimeout(response: HttpErrorResponse): boolean {
    if (!response.url.includes(this.appConfig.apiUrl) && !response.url.includes('localhost:4200')) {
      // Response from other APIs should not send the site timeout.
      return false;
    }

    if (response.status === 500 && response.error.messages instanceof Array
      && response.error.messages[0].exceptionDetail.message.includes('Execution Timeout Expired')) {
      this.appConfig.siteStatusMessage = `<b>Your session has timed out. Please refresh your screen to try again.`;
      this.appConfig.siteTitle = 'Session Timeout';
      return true;
    }

    return false;
  }

  private routeRequiresAuthorization(url: string): boolean {
    return url !== this.appConfig.getAuthUrl('user/login')
      && !url.includes('integration/lti/authenticate')
      && !url.includes('integration/clever/authenticate')
      && !url.includes('/account/setup?token')
      && !url.includes('/lti-advantage-authenticate')
      && !this.appConfig.isHighlightsPortalUrl;
  }
}
