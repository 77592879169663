<div class="userSearchWithDropdown">
  <input
    #input
    id="{{role.toLocaleLowerCase()}}Username{{ i }}"
    class="form-control"
    [formControl]="control"
    #typeAhead="ngbTypeahead"
    [ngbTypeahead]="searchUser"
    [inputFormatter]="formatUsername"
    [resultTemplate]="itemTemplate"
    [class.is-invalid]="checkInvalid(control)"
    (selectItem)="itemSelected($event)"
    [placeholder]="placeholderText"
  >
  <ng-template #itemTemplate let-r="result" let-index="index">
    <div
      class="typeahead-item"
      [style.width.px]="inputRef.nativeElement.clientWidth - 25"
    >
      {{formatUsername(r) + ': ' + r.fullName}}
    </div>
  </ng-template>
  <i *ngIf="isSearching" class="fa fa-circle-notch fa-spin d-inline-block input-group-append text-muted my-auto mx-2" aria-label="Searching..." id="isSearching{{ i }}"></i>
</div>
