<section class="generic-confirm-modal-container">
  <header [innerHTML]="params.title"></header>
  <div *ngIf="params.body" class="body-section" [innerHTML]="params.body"></div>
  <div *ngIf="params.bodyRef" class="body-section">
    <ng-container *ngTemplateOutlet="params.bodyRef"></ng-container>
  </div>
  <footer>
    <zbp-button-component
      (clickHandler)="onCancelButtonClick()"
      [label]="params.cancel?.label ?? DEFAULT_CANCEL_MESSAGE"
      [iconName]="params.cancel?.iconName"
      [iconPosition]="params.cancel?.iconPosition"
      theme="ghost"
      type="button">
    </zbp-button-component>
    <zbp-button-component
      (clickHandler)="onConfirmButtonClick()"
      [label]="params.confirm?.label ?? DEFAULT_CONFIRM_MESSAGE"
      [iconName]="params.confirm?.iconName"
      [iconPosition]="params.confirm?.iconPosition"
      theme="primary"
      type="submit">
    </zbp-button-component>
  </footer>
</section>
