import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSchools from './schools.reducer';

export const selectSchoolsState = createFeatureSelector<fromSchools.SchoolsState>(fromSchools.schoolsFeatureKey);
export const selectSchoolsCanRefresh = createSelector(selectSchoolsState, state => !state.loadInProgress);
export const selectSchools = createSelector(selectSchoolsState, state => state.schools);
export const selectSelectedSchoolId = createSelector(selectSchoolsState, state => state.selectedSchoolId);
export const selectClassroomIcons = createSelector(selectSchoolsState, state => state.classroomIcons);
export const selectClassroomIconsUnloaded = createSelector(selectSchoolsState, state => !state.classroomIconsLoaded);

// Factory selectors.
export const selectSchoolById = (schoolId: string) => (createSelector(selectSchools, schools => (
  schools ? schools.find(s => s.schoolId === schoolId) : null
)));
