<header class="d-print-none">
  <div class="row gx-0 portal-navbar">
    <div class="col">
      <nav class="navbar navbar-toggleable navbar-expand navbar-inverse background-pattern br-primary"
           data-cy="header-navbar">
        <div id="headerLeft">
          <a class="link-logo" rel="icon" (click)="goHome($event)">
            <img class="logo-image" [src]='brandLogoData?.src' [alt]="brandLogoData?.alt">
          </a>
        </div>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <div class="navbar-nav d-flex w-100">
            <div class="navbar-left">
              <div #primaryHeaderMenu *ngFor="let menu of menus">
                <!-- DROPDOWN MENU ITEM -->
                  <a
                    *ngIf="!menu.isRouted"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    class="nav-item nav-link defaultTransition dropdown-toggle routed-menu-item"
                    id="{{menu.id}}MenuLink"
                    href="#"
                    (click)="primaryMenuItemClicked($event, primaryHeaderMenu)"
                  >
                    {{ menu.label }}
                  </a>
                <div
                  #navigationDropdownMenu
                  class="dropdown-menu dropdown-menu-right dropdown-menu-container navigation-dropdown"
                  id="{{menu.id}}MenuDropdownContainer"
                >
                  <!-- ADMIN MANAGE DROPDOWN (district admin or single school school admin) -->
                  <ng-container *ngIf="isSingleAdmin(menu)">
                    <a
                      *ngFor="let adminManageOption of dropdownMenus"
                      class="dropdown-item defaultTransition no-submenu"
                      [id]="adminManageOption.id+'ManageMenuLink'"
                      [class.disabled]="adminManageOption.disabled"
                      [ngbTooltip]="adminManageOption.tooltip"
                      (click)="handleManageNavigation($event, adminManageOption, primaryHeaderMenu, navigationDropdownMenu)"
                    >{{ adminManageOption.label }}
                    </a>
                  </ng-container>

                  <!-- ADMIN MANAGE DROPDOWN (multiple schools school admin) -->
                  <ng-container *ngIf="isMultiAdmin(menu)">
                    <a
                      *ngFor="let school of headerService.schools"
                      tabindex="0"
                      data-bs-auto-close="outside"
                      class="dropdown-item defaultTransition school-menu"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      aria-haspopup="true"
                      #schoolDropdownItem
                      [id]="school.educationalUnitId+'MenuLink'"
                      (keydown)="expandSchoolSubmenu($event, primaryHeaderMenu, schoolDropdownItem)"
                      (click)="expandSchoolSubmenu($event, primaryHeaderMenu, schoolDropdownItem)">{{ school.name }}
                      <!-- SCHOOL SUBMENU -->
                      <div class="dropdown-menu dropdown-submenu school-submenu">
                        <div class="background-pattern-container background-pattern">
                          <a
                            *ngFor="let adminManageOption of headerService.adminSubMenus[school.educationalUnitId]"
                            class="dropdown-item defaultTransition"
                            href="#"
                            tabindex="0"
                            [id]="school.educationalUnitId+adminManageOption.id+'ManageMenuLink'"
                            [class.disabled]="adminManageOption.disabled"
                            [ngbTooltip]="adminManageOption.tooltip"
                            (click)="handleManageNavigation($event, adminManageOption, primaryHeaderMenu, navigationDropdownMenu)"
                          >{{ adminManageOption.label }}
                          </a>
                        </div>
                      </div>
                    </a>
                  </ng-container>

                  <!-- TEACHER PRODUCT DROPDOWN -->
                  <ng-container *ngIf="menu.id === teacherProductsMenuId">
                    <a
                      *ngFor="let product of headerService.currentMenuProducts"
                      tabindex="0"
                      data-bs-auto-close="outside"
                      class="dropdown-item defaultTransition products-menu"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      aria-haspopup="true"
                      #productDropdownItem
                      [id]="product.productLineKey+'LicensesMenuLink'"
                      (keydown)="expandProductsSubmenu($event, primaryHeaderMenu, productDropdownItem)"
                      (click)="expandProductsSubmenu($event, primaryHeaderMenu, productDropdownItem)">{{ product.name }}
                      <!-- PRODUCT VARIANT SUBMENU -->
                      <div class="dropdown-menu dropdown-submenu products-submenu">
                        <div class="background-pattern-container background-pattern">
                          <a
                            *ngFor="let variant of product.variants"
                            href="#"
                            class="dropdown-item defaultTransition px-1 py-1"
                            tabindex="0"
                            (keydown)="clickedDropdownSubMenuItem($event, product, primaryHeaderMenu, navigationDropdownMenu)"
                            (click)="clickedDropdownSubMenuItem($event, product, primaryHeaderMenu, navigationDropdownMenu)">{{ variant.name }}
                          </a>
                        </div>
                      </div>
                    </a>
                  </ng-container>

                </div>
                <!-- ROUTED EXTERNAL LINK -->
                <a
                  *ngIf="menu.isRouted && menu.isExternal"
                  class="nav-item nav-link defaultTransition"
                  id="{{menu.id}}MenuLink"
                  [attr.href]="menu.route"
                  target="_blank">
                  {{ menu.label }}
                </a>
                <!-- ROUTED INTERNAL LINK -->
                <a
                  *ngIf="menu.isRouted && !menu.isExternal"
                  class="nav-item nav-link defaultTransition"
                  id="{{menu.id}}MenuLink"
                  [routerLink]="menu.route"
                  [queryParams]="menu.params"
                  [class.nav-link--student]="user.isStudent"
                >
                  <i *ngIf="menu.iconClass" class="pe-3 fa {{ menu.iconClass }}"
                    aria-hidden="true"></i>
                  {{ menu.label }}
                  <span class="sr-only">(current)</span>
                </a>
              </div>
            </div>
            <div class="navbar-right">
              <!-- MASQUERADING INDICATOR -->
              <ng-container *ngIf="isMasqueraded">
                <zbp-button-component
                  internalId="stopMasqueradingButton"
                  class="nav-item"
                  theme="icon-white"
                  [iconName]="iconNames.mask"
                  [title]="'Stop masquerading as ' + userNameSchoolId"
                  [ariaLabel]="'Stop masquerading as ' + userNameSchoolId"
                  (click)="verifyStopMasquerade()" >
                </zbp-button-component>
              </ng-container>

              <!-- USER DROPDOWN MENU -->
              <div class="nav-item dropdown ms-auto user-menu-container">
                <a id="userMenuLink"
                  class="nav-link dropdown-toggle defaultTransition"
                  href="#"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-haspopup="true"
                  aria-expanded="false"
                  [attr.aria-label]="'User Menu for ' + userNameSchoolId"
                  data-cy="user-menu">
                  <i class="fa fa-user-alt currentUserNameIcon"></i>
                  <span id="currentUser"
                        class="currentUserName"
                  >{{ userNameSchoolId }}</span>
                </a>
                <div id="userMenuDropdown"
                  class="dropdown-menu user-menu-dropdown dropdown-menu-right w-100"
                >
                  <a id="myAccountMenuLink"
                    class="dropdown-item defaultTransition"
                    routerLink="/account"
                    routerLinkActive="active"
                    data-cy="account-link">
                    {{ 'myAccountLink' | translate }}
                  </a>
                  <a *ngIf="canSelfAssignLicenses"
                    id="licensesMenuLink"
                    class="dropdown-item defaultTransition"
                    routerLink="/learning/licenses"
                    routerLinkActive="active"
                    data-cy="licenses-link">
                    Licenses
                  </a>
                  <a *ngIf="user.roles.length > 1"
                    id="chooseAreaMenuLink"
                    class="dropdown-item defaultTransition"
                    (click)="chooseRoleClick()"
                    >
                    Choose Role
                  </a>
                  <a
                    *ngIf="isMasqueraded"
                    class="dropdown-item defaultTransition"
                    id="stopMasqueradingLink"
                    [title]="'Stop masquerading as ' + userNameSchoolId"
                    (click)="verifyStopMasquerade()">Stop Masquerading</a>
                  <div class="dropdown-divider"></div>
                  <a id="logOutMenuLink"
                    class="dropdown-item"
                    routerLink="/logout"
                    routerLinkActive="active"
                    data-cy="logout-link">
                    {{ 'logoutLink' | translate }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</header>
