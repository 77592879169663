import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrowserService } from '@core/browser.service';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';

@Component({
  selector: 'zbp-redesigned-choose-login',
  templateUrl: './redesigned-choose-login.component.html',
  styleUrls: ['./redesigned-choose-login.component.scss'],
})
export class RedesignedChooseLoginComponent implements OnInit {
  isHighlights = false;
  logoSrc: string = 'assets/zbportal/img/zb-logo.svg';
  logoAlt: string = 'My ZB Portal';
  zbpIconNames = zbpIconNames;
  containerClass: string = '';

  constructor(
    private titleService: Title,
    public browserService: BrowserService,
  ) {
  }

  readonly roleLinkButtons = [
    {
      Label: 'Teachers and administrators',
      Icon: this.zbpIconNames.admin_teacher_role,
      Route: '/login/user',
      Id: 'teacher-admin',
    },
    {
      Label: 'Parents and guardians',
      Icon: this.zbpIconNames.parent_role,
      Route: '/login/parent',
      Id: 'parent',
    },
    {
      Label: 'Students',
      Icon: this.zbpIconNames.student_role,
      Route: '/login/student',
      Id: 'students',
    },
  ];

  ngOnInit() {
    this.containerClass = 'zb-choose-role-container';
    this.titleService.setTitle('Login | My ZB Portal');
  }

  get backToZanerBloserLink(): string {
    return 'http://www.zaner-bloser.com';
  }
}
