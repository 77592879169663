import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, GuardResult, Router, RouterStateSnapshot } from '@angular/router';
import { AccessControlService } from '@services/access-control/access-control.service';
import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { AppConfigService } from '../appconfig.service';
import { AuthenticationService } from '../authentication.service';
import { UserService } from '../user.service';

/**
 * authenticationGuard ensures the user is authenticated before a route can be accessed.
 * Unauthenticated users will be redirected to the login
 */
// eslint-disable-next-line max-len
export function authenticationGuard(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GuardResult> {
  // injected services
  const accessService = inject(AccessControlService);
  const authService = inject(AuthenticationService);
  const userService = inject(UserService);
  const appConfigService = inject(AppConfigService);
  const router = inject(Router);

  const { url } = state;

  // if the route does not require authentication proceed
  if (accessService.isUnauthorizedRoute(url)) {
    return of(true);
  }
  return authService.authStatus
    .pipe(
      skipWhile(status => status === null),
      map((status) => {
        // when the user is logged in
        if (status) {
          // verify the user has agreed to the EULA
          if (!userService.hasAgreed && url !== '/agreement') {
            return router.parseUrl('/agreement');
          }
          return true;
        }
        // User is NOT actively logged in
        // Store the attempted URL for redirect later
        appConfigService.redirectUrl = url;

        // Navigate to the login page.
        const loginRouteUrl = appConfigService.findLoginRoute();
        return router.parseUrl(loginRouteUrl);
      })
    );
}
