import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrowserService } from '@core/browser.service';

@Component({
  selector: 'zbp-choose-login',
  templateUrl: './choose-login.component.html',
  styleUrls: ['../login.component.scss', './choose-login.component.scss'],
})
export class ChooseLoginComponent implements OnInit {
  isHighlights = false;

  constructor(
    private titleService: Title,
    public browserService: BrowserService,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Login | My ZB Portal');
  }

  get backToZanerBloserLink(): string {
    return 'http://www.zaner-bloser.com';
  }
}
