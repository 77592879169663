import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ZbpIconComponent } from '@shared/zbp-icon/zbp-icon';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';


/**
 * Styled red container with optional ZbpIcon.
 * Renders children content within the red wrapper.
 *
 * Icon defaults to zbpIconNames.exclamation_in_circle.
 */
@Component({
  standalone: true,
  selector: 'zbp-alert-bar-container',
  templateUrl: './alert-bar-container.component.html',
  styleUrls: ['./alert-bar-container.component.scss'],
  imports: [CommonModule, ZbpIconComponent]
})
export class AlertBarContainerComponent {
  @Input() zbpIconName: string = zbpIconNames.exclamation_in_circle;

  constructor() {}
}
