
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AnalyticsService } from '@core/analytics.service';
import { AppConfigService } from '@core/appconfig.service';
import { AuthenticationService } from '@core/authentication.service';
import { BrowserService } from '@core/browser.service';
import { CacheService } from '@core/cache.service';
import { UserService } from '@core/user.service';
import { EceRoutes } from '@ece/ece.routes';
import { LoginPageRolesByRoute } from '@shared/enums/login-page-type';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';
import { Observable, Subscription, map, of, shareReplay, skipWhile, switchMap, withLatestFrom } from 'rxjs';
import { LoginFormModel } from './login-form-model';

@Component({
  selector: 'zbp-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  errorMessage: string = '';
  showPassword = false;
  isHighlights: boolean = false;
  brandClass: string = '';
  email = '';
  password = '';
  success = null;
  subTitle = '';
  loginProcessing = false;
  loginFormMode: LoginPageRolesByRoute;
  isLoading: boolean = false;
  welcomeText: string = 'Welcome!';
  subtitleText: string = '';
  currentModeContent: LoginFormModel = null;

  private eceProductUri = '/learning/products/ece/';
  private subscriptions: Subscription[] = [];

  loginRoleTypes: LoginFormModel[] = [
    {
      id: 'admin-teacher',
      mode: LoginPageRolesByRoute.User,
      subtitle: 'Teachers and administrators',
      route: '/login/user',
      zbpIconName: zbpIconNames.admin_teacher_role,
      weight: 1,
    },
    {
      id: 'student',
      mode: LoginPageRolesByRoute.Student,
      subtitle: 'Students',
      route: '/login/student',
      zbpIconName: zbpIconNames.student_role,
      weight: 2,
    },
    {
      id: 'parent',
      mode: LoginPageRolesByRoute.Parent,
      subtitle: 'Parents and guardians',
      route: '/login/parent',
      zbpIconName: zbpIconNames.parent_role,
      weight: 0,
    },
  ];

  getContentFromButtonForFormMode(formMode: LoginPageRolesByRoute) {
    return this.loginRoleTypes.find(button => button.mode === formMode?.toString()?.toLowerCase());
  }

  goForgot(): void {
    this.router.navigateByUrl('/forgot-password');
  }

  /**
   * Login form.
   *
   * This is instantiated here so that statusChanges observable can be used in a component property.
   */
  loginForm: FormGroup = this.formBuilder.group({
    userName: this.formBuilder.control('', [Validators.required, Validators.email]),
    password: this.formBuilder.control('', [Validators.required]),
  });

  /**
   * Maps authorization status to local observable.
   */
  hasLoggedIn$: Observable<boolean> = this.authService.authStatus.pipe(
    shareReplay(),
  );

  /**
   * TODO ZBP-13633 this logic is duplicated elsewhere
   * Redirects the user if they're logged in.
   */
  redirectOnLoggedIn$: Observable<boolean> = this.hasLoggedIn$.pipe(
    withLatestFrom(this.userService.user$),
    switchMap(([loggedIn, user]) => {
      if (loggedIn) {
        const redirectPath = (user.roles.length > 1 || !user.isTeacher)
          ? ''
          : `${this.eceProductUri}${EceRoutes.WeeklyPlanner}`;
        return this.router.navigate([redirectPath]);
      }
      return Promise.resolve(false);
    }),
  );

  /**
   * Checks the userName control validity.
   */
  userNameError$: Observable<boolean> = this.loginForm.get('userName').statusChanges.pipe(
    map(status => status === 'INVALID'),
  );

  /**
   * Checks the password control validity.
   */
  passwordError$: Observable<boolean> = this.loginForm.get('password').statusChanges.pipe(
    map(status => status === 'INVALID'),
  );

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private userService: UserService,
    private appConfig: AppConfigService,
    private titleService: Title,
    private cache: CacheService,
    public browserService: BrowserService,
    private analyticsService: AnalyticsService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.data.pipe(
        skipWhile((params: Params) => !params),
        map((params: Params) => {
          this.loginFormMode = params.formMode;
          this.currentModeContent = this.getContentFromButtonForFormMode(this.loginFormMode);
          this.subtitleText = this.currentModeContent.subtitle;

          return params;
        }),
      ).subscribe(),
    );

    this.isHighlights = this.appConfig.isHighlightsPortalUrl;
    if (this.isHighlights) {
      this.redirectOnLoggedIn$.subscribe();
      this.setupHighlightsPortalData();
    } else {
      this.setupZBPortalData();
    }

    this.isLoading = false;
  }

  /**
   * Sets up the data for ZB Portal
   * Component defaults to ZB Portal data, but still needs manipulated based on route/form mode.
   * Changes display order of Role icons based on route (form mode)
   * Applies title to browser tab for ZB Brand & Role
   */
  private setupZBPortalData() {
    this.brandClass = 'zb-portal-login-form';

    if (this.cache.lastEmailUsed) {
      this.email = this.cache.lastEmailUsed;
    }

    const loginFormModeLowerCase = this.loginFormMode?.toString()?.toLowerCase();

    if (loginFormModeLowerCase === LoginPageRolesByRoute.Parent?.toString()?.toLowerCase()) {
      this.setIconWeightForMode(0, 1, 2);
      this.titleService.setTitle('Parent Login | My ZB Portal');
    } else if (loginFormModeLowerCase === LoginPageRolesByRoute.User?.toString()?.toLowerCase()) {
      this.setIconWeightForMode(1, 0, 2);
      this.titleService.setTitle('Teacher Login | My ZB Portal');
    } else if (loginFormModeLowerCase === LoginPageRolesByRoute.Student?.toString()?.toLowerCase()) {
      this.setIconWeightForMode(0, 1, 2);
      this.titleService.setTitle('Student Login | My ZB Portal');
    }

    this.loginRoleTypes = this.loginRoleTypes.sort((a, b) => a.weight - b.weight);
  }

  /*
  * Applies weight to icons.
  * Used to determine the order that the Login Role ficons display in based on the route
  * Active icon should be in the middle
  */
  private setIconWeightForMode(userWeight: number, parentsWeight: number, studentsWeight: number) {
    this.loginRoleTypes.forEach((type) => {
      switch (type.mode?.toLowerCase()) {
      case LoginPageRolesByRoute?.User:
        //eslint-disable-next-line no-param-reassign
        type.weight = userWeight;
        return type;
      case LoginPageRolesByRoute?.Student:
        //eslint-disable-next-line no-param-reassign
        type.weight = studentsWeight;
        return type;
      case LoginPageRolesByRoute?.Parent:
        //eslint-disable-next-line no-param-reassign
        type.weight = parentsWeight;
        return type;
      default:
        //eslint-disable-next-line no-param-reassign
        type.weight = userWeight;
        return type;
      }
    });
  }

  /**
   * Sets up the data for Highlights Portal
   * Applies title to browser tab for Highlights
   * HL Portal currently only supports the User mode (Admins and Teachers)
   */
  private setupHighlightsPortalData() {
    this.titleService.setTitle('Login | My Highlights Portal');
    this.welcomeText = 'Welcome';
    this.subtitleText = 'early childhood educators';
  }

  /**
   * Toggles whether to show password or not.
   */
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  private checkAndShowEmailAndPasswordRequiredErrors() {
    // show field required errors when form is submitted & not touched
    if (!this.loginForm.valid) {
      const emailControlHasErrors = this.loginForm.get('userName')?.errors?.['required'];
      const passwordControlHasErrors = this.loginForm.get('password')?.errors?.['required'];

      if (emailControlHasErrors) {
        // show Email required error
        this.userNameError$ = of(true);
      }

      if (passwordControlHasErrors) {
        // show Password required error
        this.passwordError$ = of(true);
      }
    }
  }

  private clearMessages(): void {
    this.errorMessage = null;
    this.success = null;
    this.passwordError$ = of(false);
    this.userNameError$ = of(false);
  }

  /**
   * Handles login form submission for both brands.
   * Form validation is handled inline in the template per specification.
   */

  login() {
    this.clearMessages();
    if (!this.loginForm.valid) {
      this.checkAndShowEmailAndPasswordRequiredErrors();
    } else if (this.loginForm.valid) {
      this.loginProcessing = true;
      this.errorMessage = null;
      const { userName, password } = this.loginForm.value;

      this.authService.login(userName, password)
        .subscribe((res) => {
          if (res.success) {
            this.cache.lastEmailUsed = this.email;
            if (this.loginFormMode === LoginPageRolesByRoute.Parent) {
              this.cache.setParentAsLastLoginPathUsed();
            } else {
              this.cache.setTeacherAsLastLoginPathUsed();
            }
            // Unique to ZB Portal
            if (!this.isHighlights) {
              this.analyticsService.setUserProperties();
              this.router.navigateByUrl('');
            }
          } else if (res.messages) {
            const [error] = res.messages;
            this.errorMessage = error;
            this.loginProcessing = false;
          } else {
            this.errorMessage = 'An unknown application error occurred.';
            this.loginProcessing = false;
          }
        }, () => {
          this.loginProcessing = false;
        });
    } else {
      this.loginProcessing = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(sub => sub.unsubscribe());
  }
}
