<section class="user-announcement-modal-container">
  <header class="user-announcement-modal-header">
    <h4 class="styled-heading"
        [innerHTML]="params.title"
    ></h4>
    <zbp-button-component
      [iconName]="zbpIconNames.x_cross"
      theme="icon-white"
      iconSize="font-size"
      (clickHandler)="onXButtonClick()">
    </zbp-button-component>
  </header>
  <div class="user-announcement-modal-body">
    <div class="user-announcement-modal-body-render"
         [innerHTML]="params.body"
    ></div>
    <footer class="user-announcement-modal-footer">
      <zbp-button-component
        (clickHandler)="onConfirmAndRemoveButtonClick()"
        label="Confirm and Remove"
        theme="secondary">
      </zbp-button-component>
    </footer>
  </div>
</section>
