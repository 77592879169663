import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GenericConfirmModalParams } from '@shared/modals/generic-confirm-modal/generic-confirm-modal-params';

/**
 * Can pass a TemplateRef as bodyRef for the modal body.
 *
 * Can also pass a string that will be sanitized, then set as the innerHTML of the body.
 */
@Component({
  selector: 'zbp-generic-confirm-modal',
  templateUrl: './generic-confirm-modal.component.html',
  styleUrls: ['./generic-confirm-modal.component.scss']
})
export class GenericConfirmModalComponent {
  static readonly DEFAULT_MODAL_DIALOG_CLASS = 'transparent-bg';

  readonly DEFAULT_CANCEL_MESSAGE = 'Cancel';
  readonly DEFAULT_CONFIRM_MESSAGE = 'Confirm';

  params: GenericConfirmModalParams;

  static open(ngbModal: NgbModal, params: GenericConfirmModalParams): NgbModalRef {
    const modalRef = ngbModal.open(
      GenericConfirmModalComponent,
      {
        ...params?.options,
        // only use defaults if params does not provide value
        modalDialogClass: params?.options?.modalDialogClass
          ?? GenericConfirmModalComponent.DEFAULT_MODAL_DIALOG_CLASS
      }
    );
    const component = modalRef.componentInstance;
    component.params = params;
    return modalRef;
  }

  constructor(private activeModal: NgbActiveModal) {
  }

  onCancelButtonClick(): void {
    this.activeModal.dismiss();
  }

  onConfirmButtonClick(): void {
    this.activeModal.close({ confirm: true });
  }
}

