import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from '@core/user.service';
import { School } from '@models/school';
import { IUser } from '@models/user';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';

import { RoleType } from '../enums/role-type';

@Component({
  standalone: true,
  selector: 'zbp-user-search-with-dropdown',
  templateUrl: './user-search-with-dropdown.component.html',
  styleUrls: ['./user-search-with-dropdown.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule, NgbPopoverModule]
})
export class UserSearchWithDropdownComponent {
  @ViewChild('input')
    inputRef: ElementRef;

  @Input() i: number;
  @Input() school: School;
  @Input() role: RoleType;
  @Input() control: FormControl;
  @Input() isInvalid: Function;
  /** Function that will be called if provided
   * if not provided, load the item into the formcontrol (used as part of create user) */
  @Input() itemSelectionFunction: (Event) => void;
  @Input() pageSize = 5;
  @Input() activeOnly;
  @Input() placeholderText = '';
  @Input() set isDisabled(value) {
    if (value) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  isSearching = false;

  searchUser = (userSearch$: Observable<string>) => (
    userSearch$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.isSearching = true;
      }),
      mergeMap(value => this.userService.searchUsers([
        { key: 'pageSize', value: this.pageSize },
        { key: 'educationalUnitId', value: this.school?.schoolId },
        { key: 'userSearchCriteria', value },
        { key: 'excludeMemberships', value: true },
        { key: 'excludeLicenses', value: true },
        { key: 'roleTypes', value: this.role },
        { key: 'activeOnly', value: this.activeOnly }
      ].filter(x => x.value))),
      map((res) => {
        if (res.success) {
          return res.response;
        }
        this.toastr.error(res.messages[0]);
        return [];
      }),
      tap(() => {
        this.isSearching = false;
      })
    )
  );

  formatUsername = (search: IUser) => search.studentUserName ?? search.username;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
  ) {
  }

  checkInvalid(control) {
    if (this.isInvalid && control) {
      return this.isInvalid(control);
    }

    return false;
  }

  itemSelected(event) {
    if (this.itemSelectionFunction) {
      // Right now this will prevent the item from being loaded into the input
      event.preventDefault();
      this.itemSelectionFunction(event);
      this.inputRef.nativeElement.value = '';
    }
  }
}
