import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserAnnouncementModalParams } from '@shared/modals/user-announcement-modal/user-announcement-modal-params';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';

@Component({
  selector: 'zbp-user-announcement-modal',
  templateUrl: './user-announcement-modal.component.html',
  styleUrls: ['./user-announcement-modal.component.scss']
})
export class UserAnnouncementModalComponent {

  static readonly DEFAULT_MODAL_DIALOG_CLASS = 'transparent-bg';

  params: UserAnnouncementModalParams;

  static open(ngbModal: NgbModal, params: UserAnnouncementModalParams): NgbModalRef {
    const modalRef = ngbModal.open(
      UserAnnouncementModalComponent,
      {
        ...params?.options,
        // only use defaults if params does not provide value
        modalDialogClass: params?.options?.modalDialogClass
          ?? UserAnnouncementModalComponent.DEFAULT_MODAL_DIALOG_CLASS
      }
    );
    const component = modalRef.componentInstance;
    component.params = params;
    component.params.title = params.title ?? 'Latest Updates';
    return modalRef;
  }

  constructor(private activeModal: NgbActiveModal) {
  }

  onXButtonClick(): void {
    this.activeModal.dismiss();
  }

  onConfirmAndRemoveButtonClick(): void {
    this.activeModal.close(true);
  }

  protected readonly zbpIconNames = zbpIconNames;
}
