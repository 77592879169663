import { Injectable } from '@angular/core';
import { IFacade } from '@core/store/facade';
import { ClassroomIcon } from '@models/classroom-icon';
import { School } from '@models/school';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { SchoolsState } from './schools.reducer';
import { selectClassroomIcons,
  selectClassroomIconsUnloaded,
  selectSchoolById,
  selectSchools,
  selectSchoolsCanRefresh,
  selectSelectedSchoolId,
  selectSchoolsState, } from './schools.selectors';

@Injectable()
export class SchoolsFacade implements IFacade {
  schools$: Observable<School[]>;
  schoolsCanRefresh$: Observable<boolean>;
  selectedSchoolId$: Observable<string>;
  classroomIcons$: Observable<ClassroomIcon[]>;
  canRefreshClassroomIcons$: Observable<boolean>;
  schoolsState$: Observable<SchoolsState>;

  constructor(private store: Store<{ schools: SchoolsState }>) {
    this.schools$ = store.select(selectSchools);
    this.schoolsCanRefresh$ = store.select(selectSchoolsCanRefresh);
    this.selectedSchoolId$ = store.select(selectSelectedSchoolId);
    this.classroomIcons$ = store.select(selectClassroomIcons);
    this.canRefreshClassroomIcons$ = store.select(selectClassroomIconsUnloaded);
    this.schoolsState$ = store.select(selectSchoolsState);
  }

  get(schoolId: string): Observable<School> {
    return this.selectWithProps(selectSchoolById, schoolId);
  }

  schoolsInDistrict(districtId: string): Observable<School[]> {
    return this.schools$.pipe(
      map(schools => schools.filter(s => s.districtId === districtId))
    );
  }

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }

  selectWithProps(func: Function, ...props: any[]): Observable<any> {
    return this.store.select(func(...props));
  }
}
