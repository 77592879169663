<main *ngIf="!isLoading; else preloader" class="no-access-component-container">
  <!-- Scenario 1 - No Licenses - Does not use Alert Bar Component -->
  <ng-container *ngIf="accessDeniedReason === accessDeniedReasons.NoLicenses; else alertBarContainer">
    <ng-container [ngTemplateOutlet]="isHighlightsPortal ? highlightsPortalContent : zbPortalContent"></ng-container>
    <ng-template #highlightsPortalContent>
      <section class="text-based-alert-container">
        <header>
          <h1 class="alert-title">Subscription Expired</h1>
        </header>
        <div class="alert-sub-title">
          <p>Your subscription has expired because you do not have any available licenses.</p>
          <p>Reorder licenses to renew your subscription.</p>
        </div>
        <ul class="bullet-list">
          <li class="bullet-list-item">
            Order online at <a href="https://earlylearning.highlights.com">earlylearning.highlights.com</a>
          </li>
        </ul>
      </section>
    </ng-template>

    <ng-template #zbPortalContent>
      <section class="text-based-alert-container">
        <header>
          <h1 class="alert-title">Subscription Expired</h1>
        </header>
        <div class="alert-sub-title">
          <p>Your subscription has expired because you do not have any available licenses.</p>
          <p>Reorder materials and/or licenses to renew your subscription.</p>
        </div>
        <ul class="bullet-list">
          <li class="bullet-list-item">
            Order online at <a href="https://shop.zaner-bloser.com">shop.zaner-bloser.com</a>
          </li>
          <li class="bullet-list-item">
            Speak with a Customer Experience Specialist at <a href="tel:8004213018" aria-label="8 0 0. 4 2 1. 3 0 1 8">(800) 421-3018</a>
          </li>
          <li class="bullet-list-item">
            Email us at <a [href]="'mailto:customerexperience@zaner-bloser.com'+ '?subject=' + school.name">customerexperience&#64;zaner-bloser.com</a>
          </li>
          <li class="bullet-list-item">
            Fax in your Purchase Order at <a href="fax:8009926087" aria-label="8 0 0. 9 9 2. 6 0 8 7">(800) 992-6087</a>
          </li>
          <li class="bullet-list-item">
            Work with your area's <a href="https://www.zaner-bloser.com/contact/find-your-rep.php">sales representative</a>
          </li>
        </ul>
      </section>
    </ng-template>
  
  </ng-container>

  <ng-template #alertBarContainer>
    <div class="alert-bar-section">
      <header class="alert-bar-header">
        <h1 class="alert-title">Subscription Not Active</h1>
        <p>Your subscription is not active because:</p>
      </header>

      <!-- Scenario 2 - Future Licenses -->
      <ng-container *ngIf="accessDeniedReason === accessDeniedReasons.OnlyFutureLicenses">
        <zbp-alert-bar-container>
          <span>Your license start date is in the future. <b>Please access {{brandPortalText}} starting on <time [attr.datetime]="machineReadableFutureDate">{{futureDate}}</time></b>.</span>
        </zbp-alert-bar-container>
          
        <p>If you have questions about your license start date, please contact your administrator{{school.districtAdmins.length ? ':' : '.'}}</p>
        <ng-container [ngTemplateOutlet]="adminContact"></ng-container>
        <ng-container [ngTemplateOutlet]="techSupport"></ng-container>
      </ng-container>
      
      <!-- Scenario 3 - No School ID Set, No Valid Licenses, No Distributed Licences -->
      <ng-container *ngIf="accessDeniedReason === accessDeniedReasons.NoSchoolIdNorActiveLicenses">
        <zbp-alert-bar-container>
          <span>The school ID has not been set up for your school.</span>
        </zbp-alert-bar-container>
        <p>Please work with your district administrator to set up a school ID for your school.</p>
        <ng-container [ngTemplateOutlet]="adminContact"></ng-container>
        <ng-container [ngTemplateOutlet]="techSupport"></ng-container>
      </ng-container>
      
      <!-- Scenario 4 - No Active Licenses, No Distributed Licenses, School ID is set -->
      <ng-container *ngIf="accessDeniedReason === accessDeniedReasons.NoActiveNorDistributedLicensesHasSchoolId">
        <section class="col px-5 d-flex flex-column justify-content-center align-items-center">
          <zbp-alert-bar-container>
            <span>Your license start date has not been set.</span>
          </zbp-alert-bar-container>
          <p>Please work with your district administrator to choose a license start date for your digital products.</p>
          <ng-container [ngTemplateOutlet]="adminContact"></ng-container>
          <ng-container [ngTemplateOutlet]="techSupport"></ng-container>
        </section>
      </ng-container>
      
      <!-- Scenario 5 - No Distributed Licenses, Has Active License, School ID is set -->
      <ng-container *ngIf="accessDeniedReason === accessDeniedReasons.NoDistributedLicensesHasSchoolIdAndSActiveLicense">
        <section class="col px-5 d-flex flex-column justify-content-center align-items-center">
          <zbp-alert-bar-container>
            <span>Licenses purchased by the district have not been distributed to your school.</span>
          </zbp-alert-bar-container>
          <p>Please work with your district administrator to distribute these licenses to your school.</p>
          <ng-container [ngTemplateOutlet]="adminContact"></ng-container>
          <ng-container [ngTemplateOutlet]="techSupport"></ng-container>
        </section>
      </ng-container>
    </div>
  </ng-template>
</main>

<ng-template #adminContact>
  <span *ngFor="let districtAdmin of school.districtAdmins;" class="admin-info">
    <!-- Hide label from screen readers and added aria-label to link for better screen-reader experience -->
    <p class="admin-name"
      aria-hidden="true">
      {{districtAdmin.firstName}} {{districtAdmin.lastName}}, &nbsp;
    </p>
    <a 
      class="admin-email-address"
      [attr.aria-label]="districtAdmin.firstName + ' ' + districtAdmin.lastName + 'at ' + districtAdmin.email"
      [href]="'mailto:' + districtAdmin.email + '?subject=' + school.name"
    >
      {{districtAdmin.email}}
    </a>
  </span>
</ng-template>

<ng-template #techSupport>
  <ng-container *ngIf="!isHighlightsPortal">
    <p>For additional assistance, contact <a [href]="techSupportLink" class="tech-support-link">Zaner-Bloser tech support.</a></p>
  </ng-container>
</ng-template>

<ng-template #preloader>
  <div class="preloader spinner">
    <zbp-preloader></zbp-preloader>
  </div>
</ng-template>