import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ZbpIconNames } from '../../login/branded-login/login-form/login-form-model';

/** A collection of data types for {@link ZbpDataTableComponent}. */
export namespace ZbpDataTable {

  export enum TableTheme {
    blueAndWhite = 'blueAndWhite',
    transparent = 'transparent'
  }

  /** See {@link TextCell}. */
  export const TEXT_CELL = 'text';

  /** Displays as just raw text in the table. */
  export interface TextCell {
    type: typeof TEXT_CELL;
    text: string,
    tooltip?: string,
    ariaLabel?: string,
  }

  /** See {@link TextListCell}. */
  export const TEXT_LIST_CELL = 'text-list';

  /** Displays as a comma-separated list in the table, using `ul` and `li` to preserve semantics. */
  export interface TextListCell {
    type: typeof TEXT_LIST_CELL;
    textList: string[];
    tooltip?: string,
    ariaLabel?: string,
  }

  /** See {@link LinkCell}. */
  export const LINK_CELL = 'link';

  /** Displays as a text-link styled button in the table, and allows the caller to provide an `onClick` callback. */
  export interface LinkCell {
    type: typeof LINK_CELL;
    text: string;
    onClick: () => void,
    tooltip?: string,
    ariaLabel?: string,
  }

  /** Configuration for {@link ActionListCell}.*/
  export interface Action {
    iconName: ZbpIconNames;
    disabled?: boolean,
    onClick?: () => void,
    tooltip?: string,
    ariaLabel?: string,
  }

  /** See {@link ActionListCell}. */
  export const ACTION_LIST_CELL = 'action-list';

  /** Displays as a group of icon buttons in the table, and allows each action to be specified individually. */
  export type ActionListCell = {
    type: typeof ACTION_LIST_CELL,
    actions: Action[],
  };

  /** See {@link CheckboxCell}. */
  export const CHECKBOX_CELL = 'checkbox-cell';

  /**
   * Displays as ainput checkbox as a Form Control for a form in the table
   * Allows the caller to provide an additional click handler that will be called after the checkbox is toggled.
   * Can take in custom ariaLabel for better accessibility.
   * If ariaLabel not provided, defaults to ariaLabel of 'checkbox' + (index + 1).
   */
  export type CheckboxCell = {
    type: typeof CHECKBOX_CELL,
    formControlName: string,
    ariaLabel?: string,
    additionalClickHandler?: () => void,
  };

  /** See {@link BehaviorSubjectCell}. */
  export const BEHAVIOR_SUBJECT_CELL = 'behavior-subject-cell';

  /** Dispays the value of Behavior Subject in the table */
  export type BehaviorSubjectCell = {
    type: typeof BEHAVIOR_SUBJECT_CELL,
    value: BehaviorSubject<any>,
    tooltip?: string,
    ariaLabel?: string,
  };

  /**
   * Represents a cell in a row for {@link ZbpDataTableComponent}.
   *
   * __Developer Notes:__
   * - To add more cell types, continue to expand this list, and implement the visuals in the component template.
   * - The `type` field is used to make the type safety work.
   *    - Example: `cell.type === 'action-list'` will make typescript collapse the type to `ActionListCell`,
   *        giving better intellisense in the IDE and better compile-time checking.
   */
  export type Cell = (
    TextCell
    | TextListCell
    | LinkCell
    | ActionListCell
    | CheckboxCell
    | BehaviorSubjectCell
    );

  /** Represents a row for {@link ZbpDataTableComponent}. */
  export interface Row {
    /** If provided, will be used with Angular trackBy setting when looping over the rows.
     * Otherwise, the index of the data will be used. */
    trackById?: string,
    /** A mapping of a column key to a cell in the row.
     * __The key MUST match a column key in order to appear in the table.__ */
    cells: Record<string, Cell>,
  }

  /** See {@link SimpleColumn}. */
  export const SIMPLE_COLUMN = 'simple-column';

  /** Represents a simple column for {@link ZbpDataTableComponent}. */
  export type SimpleColumn = {
    type: typeof SIMPLE_COLUMN;

    /** Used for tracking the column.
     * __The key MUST match the backend field for the sorting to work.__ */
    key: string,

    /** The display string to show in the table header. */
    header: string;

    /** A tooltip to display when the user hovers over the header. */
    tooltip?: string,

    /** screen reader accessibility label */
    ariaLabel?: string,
  }

  /** See {@link BlankColumn}. */
  export const BLANK_COLUMN = 'blank-column';

  /**
   * Represents a blank column for {@link ZbpDataTableComponent}
   * Requires the ariaLabel to be given for screen-reader support
   */
  export type BlankColumn =  {
    type: typeof BLANK_COLUMN;

    /** Used for tracking the column.
     * __The key MUST match the backend field for the sorting to work.__ */
    key: string,

    /** A tooltip to display when the user hovers over the header. */
    tooltip?: string,

    /** screen reader accessibility label */
    ariaLabel: string,
  };

  /** See {@link SortableColumn}. */
  export const SORTABLE_COLUMN = 'sortable-column';

  /** Represents a sortable column for {@link ZbpDataTableComponent}. */
  export type SortableColumn =  {
    type: typeof SORTABLE_COLUMN;

    /** Used for tracking the column.
     * __The key MUST match the backend field for the sorting to work.__ */
    key: string,

    /** The display string to show in the table header. */
    header: string;

    /** A tooltip to display when the user hovers over the header. */
    tooltip?: string,

    /** screen reader accessibility label */
    ariaLabel?: string,
  };

  /** See {@link CheckboxColumn}. */
  export const CHECKBOX_COLUMN = 'checkbox-column';

  /** Represents a checkbox column header (select all box) for {@link ZbpDataTableComponent}. */
  export type CheckboxColumn =  {
    type: typeof CHECKBOX_COLUMN;

    /** Used for tracking the column.
     * __The key MUST match the backend field for the sorting to work.__ */
    key: string,

    /** Used to update all the relevant checkboxes in the table. */
    formArray: FormArray<FormGroup<Record<string, FormControl<boolean>>>>;

    /** The display string to show in the table header. */
    header?: string;

    /** A tooltip to display when the user hovers over the header. */
    tooltip?: string,

    /** screen reader accessibility label */
    ariaLabel?: string,
  };

  /**
   * Represents a header in a column for {@link ZbpDataTableComponent}.
   *
   * __Developer Notes:__
   * - To add more column types, continue to expand this list, and implement the visuals in the component template.
   * - The `type` field is used to make the type safety work.
   *    - Example: `column.type === 'checkbox-column'` will make typescript collapse the type to `CheckboxColumn`,
   *        giving better intellisense in the IDE and better compile-time checking.
   */
  export type Column = (
    SimpleColumn
    | BlankColumn
    | SortableColumn
    | CheckboxColumn
    );

}
