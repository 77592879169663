import { FormFieldModel } from '@models/form-field';

export const generalUserFields = {
  roleType: {
    name: 'roleType',
    label: 'Role Type',
  } as FormFieldModel,
  externalId: {
    name: 'externalId',
    label: 'External ID',
  } as FormFieldModel,
  password: {
    name: 'password',
    label: 'Password',
  } as FormFieldModel,
  confirmPassword: {
    name: 'confirmPassword',
    label: 'Retype Password',
  } as FormFieldModel,
  student: {
    name: 'student',
    label: 'Student',
  } as FormFieldModel,
  parentFirstName: {
    name: 'parentFirstName',
    label: 'Parent First Name',
  } as FormFieldModel,
  parentLastName: {
    name: 'parentLastName',
    label: 'Parent Last Name',
  } as FormFieldModel,
  parentUsername: {
    name: 'parentUsername',
    label: 'Parent Email',
  } as FormFieldModel,
  firstName: {
    name: 'firstName',
    label: 'First Name',
  } as FormFieldModel,
  lastName: {
    name: 'lastName',
    label: 'Last Name',
  } as FormFieldModel,
  username: {
    name: 'username',
    label: 'Username',
  } as FormFieldModel,
  userId: {
    name: 'userId',
    label: 'User Id',
  } as FormFieldModel,
};

export const studentUserFields = {
  firstName: {
    name: 'firstName',
    label: 'Student First Name',
  } as FormFieldModel,
  lastName: {
    name: 'lastName',
    label: 'Student Last Name',
  } as FormFieldModel,
};

export const newUsersFormGroupName = 'newUsersGroup';

export const primaryFormControlNames = {
  newUsers: 'newUsers',
  roleType: 'roleType',
};
