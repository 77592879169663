<section 
  [class.redesigned-announcement]="useRedesignedVersion"
  id="announcements"
  *ngIf="announcements && announcements.length > 0"
  class="w-100">
  <button *ngFor="let a of announcements; let i = index; trackBy: trackById" 
    id="announcement{{i}}"
    [class.px-1]="!useRedesignedVersion"
    [class.py-2]="!useRedesignedVersion"
    class="w-100 btn btn-xl btn-tertiary announcementBanner" 
    (click)="open(a)">
    <ng-container [ngTemplateOutlet]="displayTeaser ? withTeaser : noTeaser" [ngTemplateOutletContext]="{ announcement: a }"></ng-container>
  </button>
</section>

<ng-template #noTeaser let-a="announcement">
  <i
    class="fa {{ announcementIconClass }} announcementBannerText announcementBannerIcon ms-2 me-4"
    aria-hidden="true">
  </i>
  <span class="d-inline-block announcementBannerText announcementBannerTitle">{{ a.title }}</span>
  <i class="fa {{ arrowIconClass }} ms-3 announcementBannerText " [class.ms-2]="!useRedesignedVersion" aria-hidden="true"></i>
</ng-template>

<ng-template #withTeaser let-a="announcement">
  <div class="d-flex jutify-content-start align-items-center announcementTeaserContainer">
    <i
      class="fa {{ announcementIconClass }} announcementBannerText announcementBannerIcon"
      [class.ms-2]="!useRedesignedVersion"
      [class.me-4]="!useRedesignedVersion"
      aria-hidden="true">
    </i>
    <div class="announcement-content">
      <div class="fw-bold announcementBannerText announcementBannerTitle">{{ a.title }}</div>
      <small class="announcementBannerText announcementBannerTeaser" [innerHTML]="renderTeaser(a.message)"></small>
      <i *ngIf="!useRedesignedVersion; else redesignedArrowRight" class="fa {{ arrowIconClass }} ms-3 announcementBannerText" aria-hidden="true"></i>
      <ng-template #redesignedArrowRight>
        <zbp-icon
          [iconName]="zbpIconNames.arrow_right"
          class="redesigned-arrow-right"
        >
      </zbp-icon>
      </ng-template>
      
    </div>
  </div>
</ng-template>
